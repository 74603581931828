import React, {useEffect, useState} from 'react';
import { Grid, Box, Container, useMediaQuery, FormControlLabel, Switch } from '@mui/material'

import axios from 'axios';

import * as theme from '../../../theme'
import Alert from '../../main/Custom/Alert';
import { MainButton } from '../../custom/button';
import { IntegerTextField, TextField } from '../../custom/field';
import { ASSISTANT } from '../../../apiEndpoints';
import TextArea from '../../custom/textarea/TextArea';

// import { chatgptModels } from '../constants/options'; 
import { InputField } from '../../custom/input';
import { SelectBox } from '../../custom/select';
import MultiSelectBusinesses from '../../custom/select/MultiSelectBox';


export default function CreateAssistant({token, setAPILoading, businessOptions}) {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [isSetForIndividual, setIsForIndividual] = useState(false)
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    message: ""
   });
   
  
   const handleAlertOpen = (title, message) => {
    setAlert({
      open: true,
      title: title,
      message: message
    });
   };

   const handleAlertClose = () => {
    setAlert({
      open: false,
      title: "",
      message: ""
    });
   };


  const handleFormChange = (event) => {
    setFormData((prevValues) => ({
        ...prevValues,
        [event.target.name]: event.target.value,
    }))
  }

  const handleSelectedBusinesses = (selected) => {
    console.log('Selected assistants:', selected);
    const form = formData
    form.business = selected
    setFormData(form)
  };

  const handleCreateSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    var currentdate = new Date();
    var datetime =
      currentdate.getFullYear() +
      ',' +
      (currentdate.getMonth() + 1) +
      ',' +
      currentdate.getDate() +
      ',' +
      currentdate.getHours() +
      ',' +
      currentdate.getMinutes() +
      ',' +
      currentdate.getSeconds();

    const bodyData = new FormData();
    Object.keys(formData).forEach((key) => {
      console.log(key, formData[key])
      if (key === 'business' && formData[key].length && formData[key].length > 0) {
        const finalSelectedBusinesses = formData[key].map(b => b.value)
        bodyData.append('business', finalSelectedBusinesses);
      } else {
        bodyData.append(key, formData[key]);
      }
    });

    if (formData.files) {
      const fileInput = document.querySelector('input[name="files"]');
      const files = fileInput.files;

      for (let i = 0; i < files.length; i++) {
        bodyData.append('files', files[i]);
      }
    }

    if (formData.image) {
      const imageInput = document.querySelector('input[name="image"]');
      const imageFile = imageInput.files[0];
  
      if (imageFile) {
        bodyData.append('image', imageFile);
      }
    }

    bodyData.append('created_at', datetime);

    console.log(isSetForIndividual.valueOf())
    // bodyData.append('show_to_individual', isSetForIndividual.valueOf())

    axios
      .post(ASSISTANT, bodyData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token,
        },
      }).then(response => response.data)
      .then(data => {
        setLoading(false)
        window.location.reload()
      })
      .catch(error => {
        setLoading(false)
        const errorResponse = error.response
        handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
      });
 

  
  }

  
  return (
      
        <Container >
          <Alert alert={alert} handleAlertClose={handleAlertClose} />

          <Grid container display={'flex'} justifyContent={'center'} sx={{px: 10}}>
            {
              isMobile?

            <Box sx={{ display: { xs: 'block', md: 'none',  } }}>
            {/* Input  */}
            <Container component="main"  sx={{
                    display: "flex",
                    minHeight: '60vh',
                    padding:2
                  }}  id='signup' maxWidth="md" >
              <Box display={'inline-flex'} alignItems={'center'} justifyContent={'center'} sx={{  backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, width: '40vh'}}> 
                <Box component="form"  onSubmit={handleCreateSubmit} sx={{ color:theme.BODY_FONT }}>
                  <Grid container spacing={2}>
                  <Grid item xs={12}  >
                  <MultiSelectBusinesses businesses={businessOptions} onSelectedChange={handleSelectedBusinesses} sx={{backgroundColor: theme.BODY_BG}} />  

        {/* <SelectBox label="Business" name="business" value={formData.business} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} options={businessOptions} required /> */}
      </Grid>
                  <Grid item xs={12}  >
                    <TextField sx={{width: '100%'}} name="name" label="Name"
                        value={formData.name} onChange={handleFormChange}  required  />
                  </Grid>
                  <Grid item xs={6}  >
                    <TextField sx={{width: '100%'}} name="title" label="Title"
                        value={formData.title} onChange={handleFormChange}  required  />
                  </Grid>

                  <Grid item xs={6}  >
                    <IntegerTextField sx={{width: '100%'}} name="order_number" label="Order Number"
                        value={formData.order_number} onChange={handleFormChange}  />
                  </Grid>
                  <Grid item xs={12} >
                    <TextArea label="Description" name="description" value={formData.description} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} required />
                  </Grid>
                  <Grid item xs={12} >
                    <TextArea label="Instructions" name="instructions" value={formData.instructions} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} required />
                  </Grid>
                  <Grid item xs={12}  >
                    <TextField sx={{width: '100%'}} name="voice_id" label="Voice ID"
                        value={formData.voice_id} onChange={handleFormChange} required    />
                  </Grid>
                  {/* <Grid item xs={6} >
                    <SelectBox label="Model" name="model"  value={formData.model} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} options={chatgptModels} required />
                  </Grid> */}
                  <Grid item xs={12}>
                    <InputField accept={'image/*'} onChange={handleFormChange} sx={{width: '100%'}} label="Image" name="image" required/>
                  </Grid>
                  <Grid item xs={12}>
                    <InputField sx={{width: '100%'}} onChange={handleFormChange} label="Files" name="files" multiple={true} />
                  </Grid>

                  {/* <Grid item xs={12}>
                    <FormControlLabel control={
                      <Switch 
                        id="show_to_individual"
                        name="show_to_individual"
                        checked={isSetForIndividual}
                        onChange={() => {setIsForIndividual(!isSetForIndividual)}}
                        style={{
                          color:'#ffffff',
                          '&.Mui-checked': {
                          color: '#ffffff',
                          },
                        }}/>
                    } label="Show to Individual" />
                  </Grid> */}

                  <Grid item sx={{mt:3}} xs={12}>
                    <MainButton type='submit' buttonText="Create Assistant" loading={loading} />
                  </Grid>  
                  
                    
                  </Grid>
                </Box>
              </Box>
            </Container>
        
            
          </Box>:

<Box sx={{ display: { xs: 'none', md: 'flex',  } }}>

<Grid item xs={12} sx={{ px:5, py:5 }} >
  <Box component="form"  onSubmit={handleCreateSubmit} >
    <Grid container spacing={2}>
      <Grid item xs={12}  >
        <MultiSelectBusinesses businesses={businessOptions} onSelectedChange={handleSelectedBusinesses} sx={{backgroundColor: theme.BODY_BG}} />  

        {/* <SelectBox label="Business" name="business" value={formData.business} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} options={businessOptions} required /> */}
      </Grid>
      <Grid item xs={5}  >
        <TextField sx={{width: '100%'}} name="name" label="Name"
            value={formData.name} onChange={handleFormChange}  required  />
      </Grid>
      <Grid item xs={5}  >
        <TextField sx={{width: '100%'}} name="title" label="Title"
            value={formData.title} onChange={handleFormChange}  required  />
      </Grid>
      <Grid item xs={2}  >
        <IntegerTextField sx={{width: '100%'}} name="order_number" label="Order Number"
            value={formData.order_number} onChange={handleFormChange}  />
      </Grid>
      <Grid item xs={12} >
        <TextArea label="Description" name="description" value={formData.description} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} required />
      </Grid>
      <Grid item xs={12} >
        <TextArea label="Instructions" name="instructions" value={formData.instructions} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} required />
      </Grid>
      <Grid item xs={12}  >
        <TextField sx={{width: '100%'}} name="voice_id" label="Voice ID"
            value={formData.voice_id} onChange={handleFormChange} required    />
      </Grid>
      {/* <Grid item xs={6} >
        <SelectBox label="Model" name="model"  value={formData.model} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} options={chatgptModels} required />
      </Grid> */}
      <Grid item xs={6}>
        <InputField accept={'image/*'}  sx={{width: '100%'}} onChange={handleFormChange}  label="Image" name="image" required/>
      </Grid>
      <Grid item xs={6}>
        <InputField sx={{width: '100%'}} label="Files" onChange={handleFormChange}   name="files" multiple={true} />
      </Grid>

      {/* <Grid item xs={6}>
        <FormControlLabel control={
          <Switch 
            id="show_to_individual"
            name="show_to_individual"
            checked={isSetForIndividual}
            onChange={() => {setIsForIndividual(!isSetForIndividual)}}
            style={{
              color:'#ffffff',
              '&.Mui-checked': {
              color: '#ffffff',
              },
            }}/>
        } label="Show to Individual" />
      </Grid> */}
      <Grid item sx={{mt:3}} xs={12}>
        <MainButton type='submit' buttonText="Create Assistant" loading={loading} />
      </Grid>  
      
    </Grid>
  </Box>
</Grid>

</Box>

            }

          </Grid>
  
        </Container>
   
           
  );
}