import { useState } from 'react';

function useData() {

  function getUser() {
    const email = localStorage.getItem('email');
    const first_name = localStorage.getItem('first_name');
    const last_name = localStorage.getItem('last_name');
    const token_balance = localStorage.getItem('token_balance');
    const token_used = localStorage.getItem('token_used');
    const subscription_plan = localStorage.getItem('subscription_plan');
    var business_name = localStorage.getItem('business_name');
    business_name = (business_name === undefined || business_name === "undefined" || business_name === "Public") ? "" : business_name

    const userData = {
      email, first_name, last_name, token_balance, token_used, subscription_plan, business_name
    }

    
    return userData && userData
  }

  const [user, setUser] = useState(getUser());

  function saveUser(userData) {
    console.log(userData)
    localStorage.setItem('email', userData.email)
    localStorage.setItem('first_name', userData.first_name)
    localStorage.setItem('last_name', userData.last_name)
    localStorage.setItem('token_balance', userData.token_balance)
    localStorage.setItem('token_used', userData.token_used)
    localStorage.setItem('subscription_plan', userData.subscription_plan)
    if (userData.business_name === "Public") {
      localStorage.setItem('business_name', "")
    } else {
      localStorage.setItem('business_name', userData.business_name || "")
    }
    setUser(userData);
  };

  function removeUser() {
    localStorage.removeItem('email')
    localStorage.removeItem('first_name')
    localStorage.removeItem('last_name')
    localStorage.removeItem('token_balance')
    localStorage.removeItem('token_used')
    localStorage.removeItem('subscription_plan')
    localStorage.removeItem('business_name')
    setUser(null);
  }

  return {
    setUser: saveUser,
    user,
    removeUser
  }

}

export default useData;